<template>
  <div v-if="user">
    <Card>
      <div class="flex items-center space-x-6">
        <img v-if="user.avatar" :src="user.avatar.thumb_url" class="h-16 w-16 rounded-full" />
        <span
          v-else
          class="grid h-16 w-16 place-items-center rounded-full bg-gray-200 text-xs font-medium text-supplement"
          >{{ user.initials || "?" }}</span
        >
        <div class="space-y-1">
          <div class="text-lg font-semibold text-heading">{{ user.full_name || "-" }}</div>
          <div class="text-supplement">{{ user.acceptances }} acceptances</div>
        </div>
      </div>
    </Card>

    <DataTable
      :endpoint="`analytics/trade/users/${$route.params.id}/collections`"
      :perPage="10"
      :sort="{ name: 'asc' }"
      title="Accepted Collections"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn sortable="name" title="Collection">
            <router-link :to="{ name: 'extended-collection-analytics', params: { id: row.id } }"
              >{{ row.name }}
            </router-link>
          </DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: null,
    user: null
  }),
  async created() {
    const response = await this.$api.get(`analytics/trade/users/${this.$route.params.id}`)
    this.user = response.data.data
    this.title = `${this.user.full_name} Analytics`
  }
}
</script>
